<template>
	<div class="product-card">
		<div>
			<a
				:href="fullPartUrl"
				:title="productName"
				@click.prevent="trackProductClick"
				:alt="productName"
			>
				<div
					class="product-image"
					:style="{
						backgroundImage: `url(${decideImageURL(siteCdn, productImage, 'card')})`,
					}"
				></div>
			</a>

			<div class="product-name-container">
				<a
					:href="fullPartUrl"
					class="product-name"
					@click.prevent="trackProductClick"
				>
					{{ isOutboard ? partNumber : capWords(productName) }}
				</a>

				<div
					v-if="!isOutboard"
					style="line-height: 1"
				>
					<small v-if="!productDecoded.is_collection">
						<strong>{{ partNumber }}</strong>
						<br />
					</small>
					<small>{{ vendor }}</small>
				</div>
			</div>
		</div>

		<div
			class="product-card-body"
			:class="{ 'collection-body': hasField('collection_hash') }"
		>
			<template v-if="hasField('fitment') && hasStoredRide">
				<fitment-status
					:product-fits="productFits"
					:stored-ride-name="storedRideName"
				></fitment-status>
			</template>

			<!-- OUTBOARD MOTOR INFO -->
			<outboard-info
				v-if="isOutboard"
				:product="productDecoded"
			/>

			<inventory-info
				:csrf="csrf"
				:id="productId"
				:cda="parseInt(cdaLevel)"
				:site="site"
				:doc-key="docKey"
				:product="productDecoded"
				:auth="auth"
				:outboard="isOutboard"
				:event-category="eventCategory"
				:pixel="pixel"
				:inventory="productDecoded"
				:stock-day-min="stockDayMin"
				:stock-day-max="stockDayMax"
				:holiday="holiday"
				:add-min="addMin"
				:add-max="addMax"
				:ride="storedRide"
				:dc="dc"
				:replacements="replacements"
				:query="query"
				:user-email="userEmail"
				:user-data="userData"
				:query-pipeline="queryPipeline"
				:query-bearer-token="queryBearerToken"
				:signal-query-pipeline="signalQueryPipeline"
				:fusion-query-id="fusionQueryId"
				:app-id="appId"
				:host="host"
				:captcha-site-key="captchaSiteKey"
			/>
		</div>
	</div>
</template>

<script>
import Strings from '@/mixins/strings';
import Images from '@/mixins/images';
import Garage from '@/mixins/garage';
import Product from '@/mixins/product';

export default {
	name: 'product-card',
	mixins: [Strings, Images, Garage, Product],
	props: {
		product: { type: [String, Object, Array] },
		csrf: { type: String },
		site: { type: String, default: 'pz' },
		cdaLevel: { type: [String, Number], default: 0 },
		auth: { type: [String, Number], default: 0 },
		eventCategory: { type: String, default: 'Product Card' },
		breadcrumbRef: { type: String, default: null },
		pixel: { type: String, default: null },
		stockDayMin: { default: '1' },
		stockDayMax: { default: '2' },
		addMin: { default: '0' },
		addMax: { default: '0' },
		holiday: { type: Boolean, default: false },
		dc: { type: String },
		replacements: { type: Boolean },
		query: { type: String, default: null },
		queryPipeline: { type: String },
		queryBearerToken: { type: String },
		userData: { type: String },
		userEmail: { type: String },
		signalQueryPipeline: { type: String },
		productPosition: { type: [String, Number], default: null },
		fusionQueryId: { type: String },
		fusionUserId: { type: String },
		host: { type: String },
		appId: { type: [String, Number] },
		captchaSiteKey: { type: String },
	},
	components: {
		OutboardInfo: () => import('@/components/product-card/OutboardInfo.vue'),
		InventoryInfo: () => import('@/components/product-card/InventoryInfo.vue'),
		FitmentStatus: () => import('@/components/widgets/FitmentStatus.vue'),
	},
	beforeMount() {
		this.checkForStoredRide();

		if (this.hasStoredRide) {
			this.getStoredRide();
		}
	},
	computed: {
		productDecoded() {
			let json;

			try {
				let decoded = atob(this.product);
				json = JSON.parse(decoded);
			} catch (e) {
				json = this.product;
			}

			return json;
		},
		productId() {
			if (this.hasField('store_product_id')) {
				return this.productDecoded.store_product_id;
			} else if (this.hasField('product_id')) {
				return this.productDecoded.product_id;
			} else if (this.hasField('price_file')) {
				return this.productDecoded.price_file.id;
			} else {
				return null;
			}
		},
		productName() {
			if (this.hasField('collection_name') && this.productDecoded.is_collection) {
				return this.productDecoded.collection_name;
			} else if (this.hasField('name')) {
				return this.productDecoded.name;
			} else {
				return this.productDecoded.product_name;
			}
		},
		eventName() {
			if (this.hasField('is_collection')) {
				if (this.productDecoded.is_collection) return 'Collection: ' + this.productName;
			}

			return this.productName;
		},
		productImage() {
			if (this.hasField('entity')) {
				return this.productDecoded.entity.image[0];
			} else if (this.hasField('product_image')) {
				if (Array.isArray(this.productDecoded.product_image)) {
					return this.productDecoded.product_image[0];
				}
				if (typeof this.productDecoded.product_image === 'string') {
					return this.productDecoded.product_image;
				}
			} else if (this.hasField('image')) {
				if (Array.isArray(this.productDecoded.image)) {
					return this.productDecoded.image[0];
				}
				if (typeof this.productDecoded.image === 'string') {
					return this.productDecoded.image;
				}
			}
		},
		partNumber() {
			if (this.productDecoded.is_collection) {
				return '';
			}

			if (this.hasField('product_sku')) {
				return this.productDecoded.product_sku;
			}

			if (this.hasField('sku')) {
				return this.productDecoded.sku;
			}

			if (this.hasField('entity') && this.productDecoded.entity.manufacturer_part_number) {
				return this.productDecoded.entity.manufacturer_part_number[0];
			} else {
				return this.productDecoded.manufacturer_part_number;
			}
		},
		vendor() {
			let vendor = '';

			if (this.hasField('vendor_name')) {
				return this.productDecoded.vendor_name;
			}

			if (this.hasField('vendor'))
				if (this.productDecoded.vendor && Object.keys(this.productDecoded.vendor).includes('name')) {
					vendor = this.productDecoded.vendor.name;
				}

			if (this.hasField('entity') && this.productDecoded.entity.manufacturer) {
				vendor = this.productDecoded.entity.manufacturer[0];
			} else if (this.hasField('manufacturer') && this.productDecoded.manufacturer != null) {
				vendor = this.productDecoded.manufacturer;
			} else {
				vendor = this.productDecoded.vendor;
			}

			if (vendor && typeof vendor === 'string') {
				return this.capWords(vendor);
			} else {
				return '';
			}
		},
		fullPartUrl() {
			const url = new URL(this.docKey, location.origin);
			if (this.breadcrumbRef !== null && (!url.searchParams.get('ref') || url.searchParams.get('ref') === null)) {
				url.searchParams.set('ref', this.breadcrumbRef);
			}
			if (this.hasFitment && !url.searchParams.has('use_fitment') && url.pathname.includes('collection')) {
				url.searchParams.set('use_fitment', 1);
			}
			if (this.productFits && url.pathname.includes('collection')) {
				if (this.hasField('titan_sku')) {
					url.searchParams.set('titan_sku', this.productDecoded.titan_sku);
					if (this.hasField('manufacturer')) {
						url.searchParams.set('manufacturer', this.productDecoded.manufacturer);
					}
				}
			}
			return url.toString();
		},
		hasFitment() {
			if (this.hasField('fitment')) {
				if (Array.isArray(this.productDecoded.fitment)) {
					return !this.productDecoded.fitment[0].includes('vehicle');
				} else {
					return !!Object.keys(this.productDecoded.fitment).includes('vehicle');
				}
			}
			return false;
		},
		docKey() {
			if (this.hasField('doc_key')) {
				return this.productDecoded.doc_key;
			} else {
				return this.productDecoded.product_doc_key;
			}
		},
		isOutboard() {
			if (this.hasField('price_file')) {
				return this.productDecoded.price_file.type_id === '3';
			} else {
				return this.productDecoded.type_id === '3';
			}

			if (this.hasField('entity')) {
				return this.productDecoded.entity.product_type[0] === '3';
			}
		},
		vehicleType() {
			if (this.site === 'pz') return 'ride';
			if (this.site === 'bn') return 'engine';
		},
		siteCdn() {
			// const cf = "cdn-cgi/image/width=270,quality=80/";

			switch (this.site) {
				case 'pz':
					return 'cdn.partzilla.com';
				case 'bn':
					return 'cdn.boats.net';
				case 'fd':
					return 'cdn.firedog.com';
			}
		},
		userDataDecoded() {
			return atob(this.userData);
		},
		findSiteName() {
			if (this.site === 'pz') return 'Partzilla';
			if (this.site === 'bn') return 'Boats.net';
			if (this.site === 'fd') return 'Firedog';
		},
		queryForFusion() {
			if (this.query !== undefined && this.query !== null && this.query !== '' && !this.query) {
				return this.query;
			}

			return this.productName;
		},
	},

	methods: {
		hasField(field) {
			return Object.keys(this.productDecoded).includes(field);
		},
		trackProductClick() {
			this.gaEcommerceSelectItem();

			// Fusion
			if (this.pixel) {
				const headers = new Headers();
				const body = {
					sobj: this.pixel,
					signal_type: 'click',
				};
				const options = {
					method: 'POST',
					headers,
					body: JSON.stringify(body),
				};
				headers.append('Content-Type', 'application/json');
				fetch('/search/pixel', options);
				// axios.post("/search/pixel", { sobj: this.pixel, signal_type: "click" });
			}

			this.sendEventToFusion('click');
		},
		gaEcommerceSelectItem() {
			const eventOptions = {
				detail: {
					name: 'select_item',
					data: {
						listId: this.eventCategory,
						listName: this.eventCategory,
					},
					ecommerce: {
						products: this.productData,
						custom: {
							listId: this.eventCategory,
							listName: this.eventCategory
						}
					}
				}
			};
			const event = new CustomEvent('new-metrics-event', eventOptions);
			window.dispatchEvent(event);
		},
	},
};
</script>

<style lang="scss">
.product-cards {
	display: grid;
	grid-template-rows: auto;
	gap: 1rem;
	margin: 2rem 0;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	.product-card-container {
		display: flex;
		.product-card {
			border: 1px solid #e3e3e3;
			border-radius: 0;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			justify-content: space-between;
			padding: 0.5rem 1rem;
			width: 100%;

			.product-image {
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				width: 100%;
				height: 22%;
				min-height: 200px;
				margin-bottom: 0.75rem;
			}

			.product-name-container {
				.product-name {
					word-break: break-word;
					text-wrap: balance;
					word-wrap: break-word;
				}
			}

			.product-card-body {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				line-height: 1.2;
				padding: 0;

				&.collection-body {
					justify-content: flex-start;
				}

				small {
					color: #777;
				}

				.outboard-info {
					.outboard-info-row {
						display: flex;

						.outboard-info-label,
						.outboard-info-data {
							flex-basis: 50%;
						}
					}
				}

				.buttons {
					margin-bottom: 12px;
				}

				.product-card-divider {
					margin: 12px 0;
				}

				a.product-name {
					font-weight: 700;
					display: block;
					width: 200px;
					// white-space: nowrap;
					// overflow: hidden;
					// text-overflow: ellipsis;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.product-cards {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		&.single-product {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}
}

@media screen and (min-width: 1536px) {
	.product-cards {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

@media screen and (min-width: 990px) and (max-width: 1536px) {
	.product-cards {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	.product-cards.cards-feed {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media (max-width: 500px) {
	.product-cards {
		.product-card-container {
			.product-card {
				.product-image {
					min-height: 120px;
				}
			}
		}
	}
}
</style>
